import 'abortcontroller-polyfill'
const win: any = window
win.React = win.EMP_ADAPTER_REACT.React
win.ReactDOM = win.EMP_ADAPTER_REACT.ReactDOM
win.ReactRouterDOM = win.EMP_ADAPTER_REACT.ReactRouterDOM
win.YoFastModeReact = win.EMP_ADAPTER_REACT
win.YoFastModeReact.MFRuntime = win.EMP_SHARE_RUNTIME.MFRuntime
win.YoFastModeReact.MFSDK = win.EMP_SHARE_RUNTIME.MFSDK
//
import('./boostrap')
